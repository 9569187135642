import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';

import { CardComponent } from './components/card/card.component';
import { DropdownComponent } from './components/dropdown/dropdown.component';
import { NotFoundComponent } from './components/not-found/not-found.component';
import { ModalComponent } from './components/modal/modal.component';
import { AlphaNumericOnlyDirective } from './directives/alpha-numeric-only.directive';
import { NumericOnlyDirective } from './directives/numeric-only.directive';
import { AlphaOnlyDirective } from './directives/alpha-only.directive';
import { ToUpperDirective } from './directives/to-upper.directive';
import { ChargerComponent } from './components/charger/charger.component';
import { StreetAddressDirective } from './directives/street-address.directive';
import { LoaderComponent } from './components/loader/loader.component';

import { MatFormFieldModule } from '@angular/material/form-field';
import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';
import { MatTabsModule } from '@angular/material/tabs';
import { MatCardModule } from '@angular/material/card';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatTableModule } from '@angular/material/table';
import { MatSelectModule } from '@angular/material/select';
import { MatIconModule } from '@angular/material/icon';
import { MatDialogModule } from '@angular/material/dialog';

import { NgChartsModule } from 'ng2-charts';
@NgModule({
  declarations: [
    CardComponent,
    DropdownComponent,
    NotFoundComponent,
    ModalComponent,
    AlphaNumericOnlyDirective,
    NumericOnlyDirective,
    AlphaOnlyDirective,
    ToUpperDirective,
    ChargerComponent,
    StreetAddressDirective,
    LoaderComponent,
  ],
  imports: [
    CommonModule,
    RouterModule,
    HttpClientModule,
    MatFormFieldModule,
    MatButtonModule,
    MatIconModule,
    MatInputModule,
    MatTabsModule,
    MatCardModule,
    MatPaginatorModule,
    MatTableModule,
    MatSelectModule,
    NgChartsModule,
    FormsModule,
    MatDialogModule,
  ],
  exports: [
    CardComponent,
    DropdownComponent,
    ModalComponent,
    AlphaNumericOnlyDirective,
    NumericOnlyDirective,
    AlphaOnlyDirective,
    ToUpperDirective,
    ChargerComponent,
    StreetAddressDirective,
    MatFormFieldModule,
    MatButtonModule,
    MatIconModule,
    MatInputModule,
    MatTabsModule,
    MatCardModule,
    MatPaginatorModule,
    MatTableModule,
    MatSelectModule,
    NgChartsModule,
    FormsModule,
    CommonModule,
    MatDialogModule,
  ],
})
export class SharedModule {}
