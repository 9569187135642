<nav class="navbar">
  <div id="navbar-menu" class="navbar-menu" *ngIf="logged">
    <div *ngIf="logo" class="logo">
      <img src="../../../../../assets/images/DMS_Logo.svg" alt="DMS" />
    </div>
    <div class="sesion-actions" *ngIf="logged">
      <p class="user-name">{{ username }}</p>
      <button
        (click)="logout()"
        id="navbar-username-btn"
        class="navbar-close-btn"
      >
        Cerrar sesión
      </button>
    </div>
  </div>
</nav>
