import { Directive, ElementRef, HostListener } from '@angular/core';

@Directive({
  selector: '[alphaOnly]',
})
export class AlphaOnlyDirective {
  constructor(private el: ElementRef) {}
  @HostListener('input', ['$event']) onInputChange(event: any) {
    const initialValue = this.el.nativeElement.value;
    this.el.nativeElement.value = initialValue
      .replace(/[^a-z\s]/gi, '')
      .trimLeft();
    if (initialValue !== this.el.nativeElement.value) {
      event.stopPropagation();
    }
  }
}
