<div class="notfound__container">
  <h1 class="title">404</h1>
  <span class="subtitle">Disculpa las molestias trata de nuevo mas tarde</span>
  <button
    [routerLink]="['/admin']"
    routerLinkActive="router-link-active"
    class="redirect__button"
  >
    PANEL
  </button>
</div>
