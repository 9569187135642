import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class LoadingService {
  loadingSubject = new BehaviorSubject<any>({
    loading: false,
    args: {},
  });

  constructor() {}

  setLoading(loading, args = {}) {
    this.loadingSubject.next({
      loading,
      args,
    });
  }
}
