import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router, RoutesRecognized } from '@angular/router';
import { delay } from 'rxjs';
import { AuthsessionService } from '../../services/auth/authsession.service';

@Component({
  selector: 'dms-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss'],
})
export class NavbarComponent implements OnDestroy {
  logo: boolean = false;
  public logged: boolean = false;
  private $routerEvents;
  username = '';

  constructor(
    private readonly router: Router,
    private authSessionService: AuthsessionService
  ) {
    this.$routerEvents = this.router.events.subscribe((resp) => {
      if (resp instanceof RoutesRecognized) {
        this.logo = true;
        this.logged =
          resp?.url.slice(1) === 'login' || resp?.url.slice(1) === ''
            ? false
            : true;
        if (this.logged) {
          this.username = this.authSessionService.getAllData().nombre;
        }
      }
    });
  }

  public logout(): void {
    this.router.navigate(['/login']);
    this.logged = false;
    localStorage.clear();
    localStorage.removeItem('userSession');
    localStorage.removeItem('userSession');
    this.logo = false;
  }

  ngOnDestroy() {
    this.$routerEvents.unsubscribe();
  }
}
