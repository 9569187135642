import { Component, Input } from '@angular/core';

@Component({
  selector: 'dnf-charger',
  templateUrl: './charger.component.html',
  styleUrls: ['./charger.component.scss'],
})
export class ChargerComponent {
  @Input() title: String = '';
  @Input() subtitle: String = '';
}
