<div class="center">
  <div class="marging">
    <div class="loadingio-spinner-spinner-pit8ukp6f9i">
      <div class="ldio-ozt5w8mnlqg">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
    </div>
  </div>
  <p class="text">{{ title }}</p>
  <p class="text2">{{ subtitle }}</p>
</div>
