<div class="dropdown-container">
  <div class="dropdown">
    <div class="dropdown-list">
      <ul>
        <li>uno</li>
        <li>dos</li>
        <li>tres</li>
      </ul>
    </div>
  </div>
</div>
