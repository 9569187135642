import { Directive, ElementRef, HostListener } from '@angular/core';

@Directive({
  selector: '[toUpper]',
})
export class ToUpperDirective {
  constructor(private el: ElementRef) {}
  @HostListener('input', ['$event']) onInputChange() {
    const initialValue = this.el.nativeElement.value;
    this.el.nativeElement.value = initialValue.toUpperCase();
  }
}
