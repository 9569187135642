import { Injectable } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { Subject } from 'rxjs';
import { AppComponent } from 'src/app/app.component';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class AuthsessionService {
  userDisplayNameSubject = new BehaviorSubject<string>('');

  private nameSession = 'userSession';
  private isUserLogged = new Subject();
  constructor(private cookieSvc: CookieService) {
    const username = localStorage.getItem(this.nameSession);
    if (username !== '') {
      this.setUserDisplayName(username);
    }
  }

  get getIsUserLogged() {
    return this.isUserLogged;
  }

  set setIsUserLogged(status) {
    this.isUserLogged.next(status);
  }

  setNameSession(name: string) {
    this.nameSession = name;
    return this;
  }

  setUserDisplayName(name: string) {
    localStorage.setItem(this.nameSession, name);
    this.userDisplayNameSubject.next(name);
  }

  setDataSession(user: any) {
    localStorage.setItem(this.nameSession, JSON.stringify(user));
    return this;
  }

  setDataCookie(user) {
    this.cookieSvc.set(this.nameSession, JSON.stringify(user));
  }

  getAllDataCookie() {
    return JSON.parse(this.cookieSvc.get(this.nameSession));
  }

  getAllData() {
    return JSON.parse(localStorage.getItem(this.nameSession));
  }

  getKeySession(key) {
    let data = this.getAllData();
    if (data != null) {
      if (data.hasOwnProperty(key)) {
        return data[key];
      }
    }
    return null;
  }

  setKeySession(key, value) {
    let data = this.getAllData();
    data[key] = value;
    this.setDataSession(data);
    return this;
  }

  clearSession() {
    localStorage.removeItem(this.nameSession);
    localStorage.removeItem(this.nameSession);
  }

  clearAllSession() {
    localStorage.clear();
  }

  isLogged() {
    let token = this.setNameSession('userSession').getKeySession('id_token');
    let id = this.setNameSession('userSession').getKeySession('id');
    return token && id;
  }

  checkSession() {
    if (!this.getAllData()) {
      this.clearAllSession();
      alert('\n\n¡¡¡¡¡ SESIÓN INVÁLIDA, INICIA SESIÓN DE NUEVO !!!!!');
      AppComponent.setStateLogin(false);
      window.location.href = '/#/login';
    }
  }
}
