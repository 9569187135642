import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

export enum ModalStates {
  SUCCESS,
  WARNING,
  ERROR,
}

@Injectable({
  providedIn: 'root',
})
export class ModalService {
  modalSubject = new BehaviorSubject<any>({});

  constructor() {}

  showModal(args = {}) {
    this.modalSubject.next(args);
  }
}
