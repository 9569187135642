import { Component, OnDestroy, OnInit } from '@angular/core';
import { fromEvent, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Router } from '@angular/router';
import { AuthsessionService } from './shared/services/auth/authsession.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy {
  public url: string | any;
  public static stateLogin: boolean | any;
  public loading = false;
  private unsubscriber: Subject<void> = new Subject<void>();

  constructor(
    private router: Router,
    private sessionService: AuthsessionService
  ) {}

  ngOnInit(): void {
    this.url = document.URL;
    AppComponent.stateLogin = true;

    fromEvent(window, 'popstate')
      .pipe(takeUntil(this.unsubscriber))
      .subscribe((_) => {
        this.url = document.URL;

        if (this.url.includes('/#/login') && AppComponent.stateLogin) {
          AppComponent.stateLogin = false;

          if (confirm('Estas a punto de cerrar sesion, ¿quieres continuar?')) {
            this.sessionService.clearAllSession();
            // console.log("CERRAMOS SESION Y LE ENVIAMOS A LOGIN");
            window.location.href = '/#/login';
          } else {
            // console.log("MANTENEMOS AL USUARIO EN LA PANTALLA");
            window.location.href = '/#/admin';
          }
        } else {
          AppComponent.stateLogin = true;
        }
      });
  }

  ngOnDestroy(): void {
    this.url = document.URL;

    this.unsubscriber.next();
    this.unsubscriber.complete();
  }

  static setStateLogin(state: boolean) {
    this.stateLogin = state;
  }

  onActivate() {
    window.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth',
    });
  }
}