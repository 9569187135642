import { Directive, ElementRef, HostListener } from '@angular/core';

@Directive({
  selector: '[streetAddress]',
})
export class StreetAddressDirective {
  constructor(private el: ElementRef) {}
  @HostListener('input', ['$event']) onInputChange() {
    const initialValue = this.el.nativeElement.value;
    this.el.nativeElement.value = initialValue.replace(
      /[ ](?=[ ])|[^-_,A-Za-z0-9 ]+/,
      ''
    );
    if (initialValue !== this.el.nativeElement.value) {
      event.stopPropagation();
    }
  }
}
