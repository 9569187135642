import { Component, Input } from '@angular/core';
import { delay } from 'rxjs';
import { ModalService, ModalStates } from '../../services/modal.service';

@Component({
  selector: 'dms-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.scss'],
})
export class ModalComponent {
  public readonly icons = {
    [ModalStates.SUCCESS]: '../../../../assets/images/icon-done.png',
    [ModalStates.ERROR]: '../../../../assets/images/icon-error.png',
    [ModalStates.WARNING]: '../../../../assets/images/icon-warning.png',
  };

  @Input() title = '';
  @Input() message = '';
  @Input() state = this.icons[ModalStates.SUCCESS];

  callback = null;
  visible = false;

  constructor(private modalService: ModalService) {
    this.modalService.modalSubject.pipe(delay(0)).subscribe((event) => {
      if (!('title' in event)) {
        return;
      }

      this.title = event.title || '';
      this.message = event.message || '';
      this.state = event.state
        ? this.icons[event.state]
        : this.icons[ModalStates.SUCCESS];
      this.callback = event.callback || null;
      this.visible = true;
    });
  }

  response() {
    document.getElementById('myModal')!.style.display = 'block';
  }

  closeModal() {
    this.visible = false;
    this.title = '';
    this.message = '';
    this.state = this.icons[ModalStates.SUCCESS];

    if (this.callback) {
      const tmp = this.callback;
      this.callback = null;
      tmp();
    }
  }
}
